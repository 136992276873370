.common-content{
    width: 1136px;
    margin: 65px auto 0;
    .sub-menu-container{
        display: flex;
        .side-sub-menu{
            flex-grow: 0;
            flex-shrink: 0;
            width: 252px;
            margin-right:30px;
            // border: 1px solid #ddd;
            .sub-title-item{
                line-height: 41px;
                padding-left: 12px;
                font-size: 14px;
                cursor: pointer;
                &:first-child{
                    cursor: text;
                    background: #5cafe1;
                    padding-left: 12px;
                    color: #ffffff;
                    font-size: 20px;
                }
                a{
                    color: #777;
                    font-size: 18px;
                }
            }
        }
        .sub-content{
            flex-grow: 1;
            flex-shrink: 1;
            // border: 1px solid #ddd;
        }
    }
}