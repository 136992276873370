@import '~antd/dist/antd.css';

h1, h2, h3, h4, h5, h6, p, ul, li { margin: 0; padding: 0; }
a{
    color:#000;
    &:hover{
        color: #002b9c;
    }
}
.main {
    margin-top: 183px;
    margin-bottom: 30px;
}

.contact-box {
    width: 1136px;
    margin: 0 auto;
    overflow: hidden;
    .Contact {
        width: 100%;
        border-top: 8px solid #5dafe1;
        padding-top: 22px;
        color: #646464;
    }
}

.Footer {
    background: #e9e9e9;
    line-height: 58px;
    text-align: center;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    span{
        margin-left: 10px;
    }
}


.l1eps {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
}

.l1epsc, .l2eps, .l3eps{
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    display:-moz-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.l1epsc {
    -webkit-line-clamp: 1;
}

.l3eps {
    -webkit-line-clamp: 3;
}
  