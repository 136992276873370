@font-face {
    font-family: 'OpenSansSBold';
    src: url(./assets/fonts/OpenSans-Semibold.ttf) format('ttf');
}
body {
    margin: 0;
    font-family:  "OpenSansSBold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
}
ul,
li {
    list-style: none;
    padding: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
