.NewsPage {
    width: 1136px;
    margin: 0 auto;
    padding: 30px 0;
    padding-left: 400px;
    position: relative;
    .container-news-title{
        font-size: 24px;
        text-align: left;
    }
    > h1 {
        margin: 0 0 15px;
        font-size: 24px;
        line-height: 40px;
        color: #3D3D3D;
        font-weight: 500;
        .tip {
            color: #ff5010;
        }
    }
    .container-title {
        font-size: 24px;
        text-align: left;
    }
    .pagination {
        margin-top: 20px;
        text-align: center;
        // .page-btn{
        //     font-size: 14px;
        //     margin: 0 5px;
        //     height: 23px;
        //     display: inline-block;
        //     line-height: 23px;
        //     padding: 0 8px;
        //     border: #cdcdcd 1px solid;
        //     color: #333;
        //     background: #fff;
        //     cursor: pointer;
        //     &.active, &:hover {
        //         background: #edfbf1;
        //         border: #429cd8 1px solid;
        //         color: #429cd8;
        //     }
        //     &:hover {
        //         text-decoration: underline;
        //     }
        // }
    }
}