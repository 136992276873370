.ProductsDetail {
    padding: 65px 72px;
    width: 1136px;
    margin: 0 auto;
    .product-title {
        font-size: 30px;
        line-height: 40px;
        color: #3d3d3d;
        font-weight: 500;
        > span {
            color: #ff5010;
        }
    }
    .product-detail {
        width: 1120px;
        overflow: hidden;
        .attrs {
            width: 740px;
            float: left;
            line-height: 40px;
            .attrs-title {
                font-size: 25px;
                font-weight: 400;
                color: #218ac8;
            }
            .desc-title{
                background-color: #ddd;
            }
            .attr {
                color: #171717;
                font-size: 14px;
                font-weight: 400;
                color: #777;
                .attr-name {
                    color: #ff791a;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .attr-bg{background-color: #efefef;}
        }
        .desc-attrs{
            width: 100%;
        }
        .img-box {
            width: 380px;
            float: left;
            > img {
                display: block;
                width: 100%;
            }
        }
    }
    .inquiries-box {
        margin-top: 10px;
        > h1 {
            font-size: 24px;
            line-height: 40px;
            color: #3d3d3d;
        }
        .enquiry-items-box {
            margin-top: 30px;
            overflow: hidden;
            .item {
                width: 50%;
                padding: 3px 58px 3px 3px;
                box-sizing: border-box;
                float: left;
                .item-name {
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    line-height: 30px;
                }
                .enquiry-item-input {
                    width: 100%;
                    border: 1px solid #dddddd;
                    line-height: 26px;
                    height: 26px;
                }
                .enquiry-item-textarea {
                    width: 100%;
                    border: 1px solid #dddddd;
                    line-height: 22px;
                    height: 80px;
                }
                &.code {
                    width: 100%;
                    .enquiry-item-input {
                        width: calc(50% - 29px);
                    }
                    .code-img {
                        display: block;
                        width: 120px;
                        height: 50px;
                        margin-top: 3px;
                    }
                }
            }
            .btns {
                width: 100%;
                float: left;
                font-size: 14px;
                padding: 3px;
                display: flex;
                justify-content: center;
                > input {
                    background: #fe931f;
                    border-radius: 5px;
                    width: 60px;
                    height: 26px;
                    line-height: 26px;
                    text-align: center;
                    border: 0px;
                    color: #ffffff;
                    margin: 0 2px;
                }
            }
        }
    }
}