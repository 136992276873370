.Product {
    width: 1150px;
    margin: 65px auto;
    .product-detail {
        width: 900px;
    }
    .container-product-title{
        margin: 0;
        font-size: 24px;
        margin: 0 auto;
    }
    .product-title {
        margin: 0 auto;
        font-size: 24px;
        line-height: 40px;
        color: #3d3d3d;
        font-weight: 500;
        > span {
            color: #ff5010;
        }
    }
    .search-bar {
        margin: 50px auto 30px;
    }
    .product-list {
        .count {
            text-align: center;
        }
    }
}
.pagination-container{
    text-align: right;
    margin: 0 auto;
}
.desc-bar{
    margin: 0 auto;
    color: #777;
    line-height: 22px;
    font-size: 16px;
    font-weight: 400;
}

.sub-menu-container{
    display: flex;
    .side-sub-menu{
        flex-grow: 0;
        flex-shrink: 0;
        width: 252px;
        margin-right:30px;
        // border: 1px solid #ddd;
        .sub-title-item{
            line-height: 41px;
            padding-left: 12px;
            font-size: 14px;
            cursor: pointer;
            &:first-child{
                cursor: text;
                background: #5cafe1;
                padding-left: 12px;
                color: #ffffff;
                font-size: 20px;
            }
            a{
                color: #777;
                font-size: 18px;
            }
        }
    }
    .sub-content{
        flex-grow: 1;
        flex-shrink: 1;
        // border: 1px solid #ddd;
    }
}