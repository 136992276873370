.NewsDetail {
    width: 1136px;
    margin: 0 auto;
    padding: 65px 0;
    text-align: center;
    .container-news-detail-title{
        margin: 0;
        font-size: 24px;
    }
    > h1 {
        margin: 0 0 15px;
        font-size: 24px;
        line-height: 40px;
        color: #3d3d3d;
        font-weight: 500;
        text-align: left;
        .tip {
            color: #ff5010;
        }
    }
    .container-title-news {
        font-size: 24px;
        text-align: left;
    }
    .detail {
        padding-top: 15px;
        line-height: 20px;
        color: #3d3d3d;
        font-size: 14px;
        .news-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .time {
            font-size: 12px;
            color: #cccccc;
            margin-bottom: 10px;
        }
        .content {
            text-align: left;
        }
        .img {
            width: 355px;
        }
        .author {
            font-weight: bold;
            text-align: right;
            line-height: 20px;
        }
        .bottom-time {
            text-align: right;
        }
        .close-btn > span {
            color: #f60;
            font-size: 12px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}